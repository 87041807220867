import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import {
  AdminAddUserToGroupCommand,
  AdminCreateUserCommand,
  AdminDeleteUserCommand,
  AdminDisableUserCommand,
  AdminEnableUserCommand,
  AdminGetUserCommand,
  AdminGetUserCommandOutput,
  AdminListGroupsForUserCommand,
  AdminListGroupsForUserCommandOutput,
  AdminRemoveUserFromGroupCommand,
  AdminUserGlobalSignOutCommand,
  AttributeType,
  CognitoIdentityProviderClient,
  DeliveryMediumType,
  GroupType,
  ListUsersCommand,
  ListUsersCommandOutput,
  ListUsersInGroupCommand,
  ListUsersInGroupCommandOutput,
  MessageActionType,
  UserType
} from '@aws-sdk/client-cognito-identity-provider';
import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import {
  CancelJobExecutionCommand,
  CreateOTAUpdateCommand,
  DeleteCertificateCommand,
  DeleteCertificateCommandOutput,
  DeleteOTAUpdateCommand,
  DeleteThingCommand,
  DescribeThingCommand,
  DetachPolicyCommand,
  DetachPolicyCommandOutput,
  DetachThingPrincipalCommand,
  DetachThingPrincipalCommandOutput,
  GetOTAUpdateCommand,
  GetOTAUpdateCommandOutput,
  IoTClient,
  JobExecutionSummaryForJob,
  ListJobExecutionsForJobCommand,
  ListJobExecutionsForJobCommandOutput,
  ListOTAUpdatesCommand,
  ListOTAUpdatesCommandOutput,
  ListThingPrincipalsCommand,
  ListThingPrincipalsCommandOutput,
  ListThingsCommand,
  ListThingsCommandOutput,
  OTAUpdateInfo,
  OTAUpdateSummary,
  S3Location,
  TargetSelection,
  ThingAttribute,
  UpdateCertificateCommand,
  UpdateCertificateCommandOutput
} from '@aws-sdk/client-iot';
import {
  CognitoIdentityCredentialProvider,
  CognitoIdentityCredentials,
  fromCognitoIdentityPool
} from '@aws-sdk/credential-provider-cognito-identity';
import {
  DeleteCommand,
  DynamoDBDocumentClient,
  PutCommand,
  QueryCommand,
  QueryCommandOutput,
  ScanCommand,
  ScanCommandOutput,
  UpdateCommand
} from '@aws-sdk/lib-dynamodb';
import type { NativeAttributeValue } from '@aws-sdk/util-dynamodb';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import {
  CustomJobExecution,
  DeviceLink,
  DeviceShadow,
  DeviceType_Singular,
  LogEntry,
  StromeePlusLogGroup,
  ProducedDevice,
  CognitoUser,
  UserInvitation,
  StromeeLogGroup,
  DeepPartial
} from '../typings';
import type { JsonObject } from 'type-fest';
import {
  CloudWatchLogsClient,
  FilteredLogEvent,
  FilterLogEventsCommand,
  FilterLogEventsCommandOutput
} from '@aws-sdk/client-cloudwatch-logs';
import {
  DeleteObjectCommand,
  GetObjectCommand,
  ListObjectsV2Command,
  ListObjectsV2CommandOutput,
  ListObjectVersionsCommand,
  ListObjectVersionsCommandOutput,
  ObjectVersion,
  PutObjectCommand,
  S3Client,
  _Object
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import {
  STROMEE_PLUS_API_DOMAIN,
  PRODUCTION_TABLE,
  LINKING_TABLE,
  DEVICE_INDEX,
  PRODUCTION_API_KEY,
  UID_REGEX,
  DEVICE_POLICY,
  THING_NAME_PREFIX,
  ADMIN_USER_GROUP,
  STROMEE_PLUS_DEFAULT_PREFIX,
  OTA_BUCKET,
  OTA_ROLE,
  THING_ARN_PREFIX,
  REGION,
  AMPLIFY_CONFIG,
  SIGNING_PROFILE,
  ADMIN_ROLE,
  CUSTOMER_TABLE,
  SUB_INDEX,
  STROMEE_DEFAULT_PREFIX,
  STROMEE_SERVICE_PREFIX,
  STROMEE_ENVIRONMENT,
  SUPPORT_USER_GROUP,
  SUPPORT_ROLE
} from '../constants';
import type {
  CognitoUser as RealCognitoUser,
  CognitoUserAttribute,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { noop } from 'vue-class-component/lib/util';

const defaultConfig: AxiosRequestConfig = {
  baseURL: `https://${STROMEE_PLUS_API_DOMAIN}`,
  responseType: 'json'
};

let ddbClient: DynamoDBClient;
let ddbDocClient: DynamoDBDocumentClient;
let iotClient: IoTClient;
let s3Client: S3Client;
let cognitoClient: CognitoIdentityProviderClient;
let logsClient: CloudWatchLogsClient;
let provider: CognitoIdentityCredentialProvider;

async function getCredentials(): Promise<CognitoIdentityCredentialProvider> {
  return async (): Promise<CognitoIdentityCredentials> => {
    let session: CognitoUserSession = await Auth.currentSession();
    if (!provider || session.getIdToken().getExpiration() - Date.now() < 0) {
      if (session.getIdToken().getExpiration() - Date.now() < 0) {
        const user: RealCognitoUser = await Auth.currentAuthenticatedUser();
        session = await new Promise(
          (
            resolve: (res: CognitoUserSession) => void,
            reject: (err: Error) => void
          ): void =>
            user.refreshSession(
              session.getRefreshToken(),
              (err?: Error, res?: CognitoUserSession): void =>
                err || !res
                  ? reject(err || new Error('No new Session!'))
                  : resolve(res)
            )
        );
      }
      const groups: string[] =
        session.getIdToken().payload['cognito:groups'] || [];
      const customRoleArn: string | undefined = groups.includes(
        ADMIN_USER_GROUP
      )
        ? ADMIN_ROLE
        : groups.includes(SUPPORT_USER_GROUP)
        ? SUPPORT_ROLE
        : undefined;
      provider = await fromCognitoIdentityPool({
        client: new CognitoIdentityClient({
          region: REGION
        }),
        customRoleArn,
        logins: {
          [`cognito-idp.${REGION}.amazonaws.com/${AMPLIFY_CONFIG.Auth.userPoolId}`]:
            session.getIdToken().getJwtToken()
        },
        identityPoolId: AMPLIFY_CONFIG.Auth.identityPoolId
      });
    }
    return provider();
  };
}

async function getDocumentClient(): Promise<DynamoDBDocumentClient> {
  if (!ddbClient) {
    ddbClient = new DynamoDBClient({
      region: REGION,
      credentials: await getCredentials()
    });
  }
  if (!ddbDocClient) {
    ddbDocClient = DynamoDBDocumentClient.from(ddbClient, {
      marshallOptions: {
        convertEmptyValues: true,
        removeUndefinedValues: true,
        convertClassInstanceToMap: true
      },
      unmarshallOptions: {
        wrapNumbers: false
      }
    });
  }
  return ddbDocClient;
}

async function getIotClient(): Promise<IoTClient> {
  if (!iotClient) {
    iotClient = new IoTClient({
      region: REGION,
      credentials: await getCredentials()
    });
  }
  return iotClient;
}

async function getS3Client(): Promise<S3Client> {
  if (!s3Client) {
    s3Client = new S3Client({
      region: REGION,
      credentials: await getCredentials()
    });
  }
  return s3Client;
}

async function getLogsClient(): Promise<CloudWatchLogsClient> {
  if (!logsClient) {
    logsClient = new CloudWatchLogsClient({
      region: REGION,
      credentials: await getCredentials()
    });
  }
  return logsClient;
}

async function getCognitoClient(): Promise<CognitoIdentityProviderClient> {
  if (!cognitoClient) {
    cognitoClient = new CognitoIdentityProviderClient({
      region: REGION,
      credentials: await getCredentials()
    });
  }
  return cognitoClient;
}

async function getAllRows<T = Record<string, unknown>>(
  TableName: string
): Promise<T[]> {
  const ddbDocClient: DynamoDBDocumentClient = await getDocumentClient();
  const rows: T[] = [];
  let ExclusiveStartKey:
    | {
        [key: string]: NativeAttributeValue;
      }
    | undefined;
  do {
    const data: ScanCommandOutput = await ddbDocClient.send(
      new ScanCommand({
        TableName,
        ExclusiveStartKey
      })
    );
    if (data.Items && Array.isArray(data.Items)) {
      rows.push(...(data.Items as T[]));
    }
    ExclusiveStartKey = data.LastEvaluatedKey;
  } while (ExclusiveStartKey);
  return rows;
}

async function getCurrentUserSub(): Promise<string> {
  const user: RealCognitoUser = await Auth.currentAuthenticatedUser();
  const attributes: CognitoUserAttribute[] = await new Promise(
    (
      resolve: (result: CognitoUserAttribute[]) => void,
      reject: (error: Error) => void
    ): void =>
      user.getUserAttributes(
        (err?: Error, result?: CognitoUserAttribute[]): void =>
          err || !result ? reject(err || new Error('no data')) : resolve(result)
      )
  );
  return attributes.find((attr: AttributeType): boolean => attr?.Name === 'sub')
    ?.Value as string;
}

export async function addProductionDevice(
  uid: string,
  type: DeviceType_Singular,
  verification: string
): Promise<ProducedDevice> {
  if (!uid || !type || !verification) {
    throw new Error('Missing Properties!');
  }
  const created: number = Date.now();
  const Item: ProducedDevice = {
    uid,
    type,
    verification,
    created
  };
  await (
    await getDocumentClient()
  ).send(
    new PutCommand({
      TableName: PRODUCTION_TABLE,
      Item,
      ConditionExpression: 'attribute_not_exists(#uid)',
      ExpressionAttributeNames: {
        '#uid': 'uid'
      }
    })
  );
  return Item;
}

export async function addProductionDeviceOfficial(
  uid: string
): Promise<ProducedDevice> {
  const data: { uid: string } = {
    uid
  };
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `POST /production HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
X-API-Key: ${PRODUCTION_API_KEY}
Content-Type: application/json

${JSON.stringify(data, undefined, 2)}`
  });
  return axios({
    ...defaultConfig,
    method: 'POST',
    url: '/production',
    headers: {
      'X-API-Key': PRODUCTION_API_KEY
    },
    data
  })
    .then((res: AxiosResponse<ProducedDevice>): ProducedDevice => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
      });
      return res.data;
    })
    .catch((error: AxiosError<{ message: string }>): ProducedDevice => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function getProductionDeviceList(): Promise<ProducedDevice[]> {
  const [devices, links, things]: [ProducedDevice[], DeviceLink[], string[]] =
    await Promise.all([
      getAllRows<ProducedDevice>(PRODUCTION_TABLE),
      getAllRows<DeviceLink>(LINKING_TABLE),
      getThingList()
    ]);
  return devices.map(
    (device: ProducedDevice): ProducedDevice => ({
      ...device,
      sub: links.find(
        (link: DeviceLink): boolean =>
          link.uid?.toUpperCase() === device.uid?.toUpperCase()
      )?.sub,
      provisioned: things.includes(device.uid?.toUpperCase()),
      connected: device.lastConnectAt
        ? device.lastConnectAt > (device.lastDisconnectAt || 0)
        : undefined
    })
  );
}

export async function updateProductionDevice(
  device: ProducedDevice
): Promise<ProducedDevice> {
  const changedAt: number = Date.now();
  const changedBy: string = await getCurrentUserSub();
  await (
    await getDocumentClient()
  ).send(
    new UpdateCommand({
      TableName: PRODUCTION_TABLE,
      Key: { uid: device.uid },
      UpdateExpression:
        'SET #verification = :verification, #changedAt = :changedAt, #changedBy = :changedBy',
      ConditionExpression: 'attribute_exists(#uid)',
      ExpressionAttributeNames: {
        '#uid': 'uid',
        '#verification': 'verification',
        '#changedAt': 'changedAt',
        '#changedBy': 'changedBy'
      },
      ExpressionAttributeValues: {
        ':verification': device.verification,
        ':changedAt': changedAt,
        ':changedBy': changedBy
      }
    })
  );
  return {
    ...device,
    changedAt,
    changedBy
  };
}

export async function removeProductionDevice(uid: string): Promise<void> {
  const [client, link]: [DynamoDBDocumentClient, DeviceLink | undefined] =
    await Promise.all([
      getDocumentClient(),
      getDeviceLink(uid).catch((): undefined => undefined)
    ]);
  await Promise.all([
    client.send(
      new DeleteCommand({
        TableName: PRODUCTION_TABLE,
        Key: { uid }
      })
    ),
    ...(link?.sub
      ? [
          client.send(
            new DeleteCommand({
              TableName: LINKING_TABLE,
              Key: { uid, sub: link.sub }
            })
          )
        ]
      : [])
  ]);
}

export async function getDeviceLink(uid: string): Promise<DeviceLink> {
  return (await getDocumentClient())
    .send(
      new QueryCommand({
        TableName: LINKING_TABLE,
        IndexName: DEVICE_INDEX,
        KeyConditionExpression: '#uid = :uid',
        ExpressionAttributeNames: {
          '#uid': 'uid'
        },
        ExpressionAttributeValues: {
          ':uid': uid
        }
      })
    )
    .then((res: QueryCommandOutput): DeviceLink => {
      if (!res?.Items?.[0]) {
        throw new Error('No Device Link found!');
      }
      return res.Items[0] as DeviceLink;
    });
}

export async function getThingList(): Promise<string[]> {
  const client: IoTClient = await getIotClient();
  const things: string[] = [];
  let nextToken: string | undefined;
  do {
    const data: ListThingsCommandOutput = await client.send(
      new ListThingsCommand({
        nextToken
      })
    );
    if (data.things && Array.isArray(data.things)) {
      things.push(
        ...data.things
          .filter((thing: ThingAttribute): boolean =>
            UID_REGEX.test(thing.thingName || '')
          )
          .map(
            (thing: ThingAttribute): string =>
              thing.attributes?.serialNumber as string
          )
      );
    }
    nextToken = data.nextToken;
  } while (nextToken);
  return things;
}

async function getAllPrincipals(
  thingName: string,
  client: IoTClient
): Promise<string[]> {
  const principals: string[] = [];
  let nextToken: string | undefined;
  do {
    const data: ListThingPrincipalsCommandOutput = await client
      .send(
        new ListThingPrincipalsCommand({
          thingName
        })
      )
      .catch(
        (): ListThingPrincipalsCommandOutput =>
          ({} as ListThingPrincipalsCommandOutput)
      );
    if (data.principals && Array.isArray(data.principals)) {
      principals.push(...data.principals);
    }
    nextToken = data.nextToken;
  } while (nextToken);
  return principals;
}

export async function removeThing(uid: string): Promise<void> {
  const iotClient: IoTClient = await getIotClient();
  const ddbDocClient: DynamoDBDocumentClient = await getDocumentClient();
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  const thingName: string = `${THING_NAME_PREFIX}${uid}`;

  const [thingExists, principals, sub]: [
    boolean,
    string[],
    string | undefined
  ] = await Promise.all([
    iotClient
      .send(
        new DescribeThingCommand({
          thingName
        })
      )
      .then((): boolean => true)
      .catch((): boolean => false),
    getAllPrincipals(thingName, iotClient),
    ddbDocClient
      .send(
        new QueryCommand({
          TableName: LINKING_TABLE,
          IndexName: DEVICE_INDEX,
          KeyConditionExpression: '#uid = :uid',
          ExpressionAttributeNames: {
            '#uid': 'uid'
          },
          ExpressionAttributeValues: {
            ':uid': uid
          }
        })
      )
      .then(
        (res: QueryCommandOutput): string | undefined =>
          res?.Items?.[0]?.sub || undefined
      )
      .catch((): undefined => undefined)
  ]);

  if (!thingExists && !principals.length && !sub) {
    return;
  }

  if (principals.length) {
    await Promise.all([
      ...principals.map(
        (principal: string): Promise<DetachThingPrincipalCommandOutput> =>
          iotClient.send(
            new DetachThingPrincipalCommand({ thingName, principal })
          )
      ),
      ...principals.map(
        (target: string): Promise<DetachPolicyCommandOutput> =>
          iotClient.send(
            new DetachPolicyCommand({ target, policyName: DEVICE_POLICY })
          )
      )
    ]);
  }

  const certificateIds: string[] = principals
    .filter((principal: string): boolean => principal.includes(':cert/'))
    .map((arn: string): string => arn.split('/').pop() as string)
    .filter(Boolean);
  if (certificateIds.length) {
    await Promise.all(
      certificateIds.map(
        (certificateId: string): Promise<UpdateCertificateCommandOutput> =>
          iotClient.send(
            new UpdateCertificateCommand({
              certificateId,
              newStatus: 'INACTIVE'
            })
          )
      )
    );
    await Promise.all(
      certificateIds.map(
        (certificateId: string): Promise<DeleteCertificateCommandOutput> =>
          iotClient.send(new DeleteCertificateCommand({ certificateId }))
      )
    );
  }

  if (thingExists) {
    await Promise.all([
      axios({
        ...defaultConfig,
        method: 'DELETE',
        url: `/admin/shadows/${thingName}`,
        headers: {
          authorization
        }
      }).catch(
        (
          error: AxiosError<Error | { errors: Array<{ msg: string }> }>
        ): void => {
          const message: string =
            (error.response?.data as Error)?.message ||
            (error.response?.data as { errors: Array<{ msg: string }> })
              ?.errors?.[0]?.msg ||
            error.message;
          throw new Error(message);
        }
      ),
      iotClient.send(new DeleteThingCommand({ thingName }))
    ]);
  }

  if (sub) {
    await ddbDocClient.send(
      new DeleteCommand({
        TableName: LINKING_TABLE,
        Key: {
          sub,
          uid
        }
      })
    );
  }
}

export async function getShadow(uid: string): Promise<DeviceShadow> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  return axios({
    ...defaultConfig,
    method: 'GET',
    url: `/admin/shadows/${uid}`,
    headers: {
      authorization
    }
  })
    .then((res: AxiosResponse<DeviceShadow>): DeviceShadow => res.data)
    .catch(
      (
        error: AxiosError<Error | { errors: Array<{ msg: string }> }>
      ): DeviceShadow => {
        const message: string =
          (error.response?.data as Error)?.message ||
          (error.response?.data as { errors: Array<{ msg: string }> })
            ?.errors?.[0]?.msg ||
          error.message;
        throw new Error(message);
      }
    );
}

export async function updateShadow(
  uid: string,
  data: DeviceShadow | DeepPartial<DeviceShadow>
): Promise<void> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  await axios({
    ...defaultConfig,
    method: 'POST',
    url: `/admin/shadows/${uid}`,
    headers: {
      authorization
    },
    data
  }).catch(
    (error: AxiosError<Error | { errors: Array<{ msg: string }> }>): void => {
      const message: string =
        (error.response?.data as Error)?.message ||
        (error.response?.data as { errors: Array<{ msg: string }> })
          ?.errors?.[0]?.msg ||
        error.message;
      throw new Error(message);
    }
  );
}

export async function removeDeviceLink(
  sub: string,
  uid: string
): Promise<void> {
  await (
    await getDocumentClient()
  ).send(
    new DeleteCommand({
      TableName: LINKING_TABLE,
      Key: {
        sub,
        uid
      }
    })
  );
  await updateShadow(uid, {
    state: {
      desired: {
        sub: null
      }
    }
  });
}

async function getAllUsers(
  client: CognitoIdentityProviderClient
): Promise<CognitoUser[]> {
  const users: Array<CognitoUser> = [];
  let PaginationToken: string | undefined;
  do {
    const data: ListUsersCommandOutput = await client.send(
      new ListUsersCommand({
        UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
        PaginationToken
      })
    );
    if (data.Users && Array.isArray(data.Users)) {
      users.push(
        ...data.Users.map(
          (user: UserType): CognitoUser => ({
            sub: user.Attributes?.find(
              (attr: AttributeType): boolean => attr?.Name === 'sub'
            )?.Value as string,
            email: user.Attributes?.find(
              (attr: AttributeType): boolean => attr?.Name === 'email'
            )?.Value as string
          })
        )
      );
    }
    PaginationToken = data.PaginationToken;
  } while (PaginationToken);
  return users;
}

async function getAllUsersInGroup(
  client: CognitoIdentityProviderClient,
  GroupName: string
): Promise<string[]> {
  const users: string[] = [];
  let NextToken: string | undefined;
  do {
    const data: ListUsersInGroupCommandOutput = await client.send(
      new ListUsersInGroupCommand({
        UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
        GroupName,
        NextToken
      })
    );
    if (data.Users && Array.isArray(data.Users)) {
      users.push(
        ...data.Users.map(
          (user: UserType): string =>
            user.Attributes?.find(
              (attr: AttributeType): boolean => attr?.Name === 'sub'
            )?.Value as string
        ).filter(Boolean)
      );
    }
    NextToken = data.NextToken;
  } while (NextToken);
  return users;
}

export async function getUserList(): Promise<CognitoUser[]> {
  const client: CognitoIdentityProviderClient = await getCognitoClient();
  const [allUsers, adminUsers, supportUsers]: [
    CognitoUser[],
    string[],
    string[]
  ] = await Promise.all([
    getAllUsers(client),
    getAllUsersInGroup(client, ADMIN_USER_GROUP),
    getAllUsersInGroup(client, SUPPORT_USER_GROUP)
  ]);

  return allUsers.map(
    (user: CognitoUser): CognitoUser => ({
      ...user,
      userGroup: adminUsers.includes(user.sub)
        ? ADMIN_USER_GROUP
        : supportUsers.includes(user.sub)
        ? SUPPORT_USER_GROUP
        : undefined
    })
  );
}

export async function getUser(sub: string): Promise<JsonObject> {
  const client: CognitoIdentityProviderClient = await getCognitoClient();
  return Promise.all([
    client.send(
      new AdminGetUserCommand({
        UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
        Username: sub
      })
    ),
    client.send(
      new AdminListGroupsForUserCommand({
        UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
        Username: sub
      })
    )
  ]).then(
    ([userResult, groupsResult]: [
      AdminGetUserCommandOutput,
      AdminListGroupsForUserCommandOutput
    ]): JsonObject => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { $metadata, UserAttributes, ...user }: AdminGetUserCommandOutput =
        userResult;
      return {
        ...(user as unknown as JsonObject),
        UserGroup: groupsResult.Groups?.some(
          (group: GroupType): boolean => group.GroupName === ADMIN_USER_GROUP
        )
          ? ADMIN_USER_GROUP
          : groupsResult.Groups?.some(
              (group: GroupType): boolean =>
                group.GroupName === SUPPORT_USER_GROUP
            )
          ? SUPPORT_USER_GROUP
          : '',
        UserAttributes: UserAttributes?.reduce(
          (
            all: JsonObject,
            curr: { Name?: string; Value?: string }
          ): JsonObject => {
            if (curr.Name) {
              all[curr.Name] = curr.Value;
            }
            return all;
          },
          {}
        )
      };
    }
  );
}

export async function inviteUsers(
  invites: UserInvitation[]
): Promise<{ success: string[]; resend: string[]; error: string[] }> {
  const cognitoClient: CognitoIdentityProviderClient = await getCognitoClient();
  const success: string[] = [];
  const resend: string[] = [];
  const error: string[] = [];
  for (const invite of invites) {
    try {
      await cognitoClient.send(
        new AdminCreateUserCommand({
          UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
          DesiredDeliveryMediums: [DeliveryMediumType.EMAIL],
          Username: invite.email,
          ValidationData: [
            {
              Name: 'firstname',
              Value: invite.firstname
            },
            {
              Name: 'lastname',
              Value: invite.lastname
            },
            {
              Name: 'locale',
              Value: 'de'
            },
            {
              Name: 'createdAs',
              Value: 'cognito'
            }
          ]
        })
      );
      success.push(invite.email);
    } catch (e1) {
      try {
        if ((e1 as Error).name !== 'UsernameExistsException') {
          throw e1;
        }
        await cognitoClient.send(
          new AdminCreateUserCommand({
            UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
            DesiredDeliveryMediums: [DeliveryMediumType.EMAIL],
            MessageAction: MessageActionType.RESEND,
            Username: invite.email
          })
        );
        resend.push(invite.email);
      } catch (e2) {
        // eslint-disable-next-line no-console
        console.error(invite.email, e2);
        error.push(invite.email);
      }
    }
  }
  return {
    success,
    resend,
    error
  };
}

export async function removeUserBySub(sub: string): Promise<void> {
  const [cognitoClient, ddbClient]: [
    CognitoIdentityProviderClient,
    DynamoDBDocumentClient
  ] = await Promise.all([getCognitoClient(), getDocumentClient()]);
  const customerId: string = await ddbClient
    .send(
      new QueryCommand({
        TableName: CUSTOMER_TABLE,
        IndexName: SUB_INDEX,
        KeyConditionExpression: '#sub = :sub',
        ExpressionAttributeNames: {
          '#sub': 'sub'
        },
        ExpressionAttributeValues: {
          ':sub': sub
        }
      })
    )
    .then((res: QueryCommandOutput): string => {
      if (!res?.Items?.[0]) {
        throw new Error('No Customer found!');
      }
      return res.Items[0].id;
    });
  await Promise.all([
    cognitoClient.send(
      new AdminDeleteUserCommand({
        UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
        Username: sub
      })
    ),
    ddbClient.send(
      new DeleteCommand({
        TableName: CUSTOMER_TABLE,
        Key: {
          id: customerId
        }
      })
    )
  ]);
  if (sub === (await getCurrentUserSub())) {
    provider = undefined as unknown as CognitoIdentityCredentialProvider;
    await Auth.signOut();
  }
}

export async function setUserGroup(
  sub: string,
  GroupName?: string
): Promise<void> {
  const client: CognitoIdentityProviderClient = await getCognitoClient();
  if (GroupName) {
    await client.send(
      new AdminAddUserToGroupCommand({
        UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
        GroupName,
        Username: sub
      })
    );
  }
  await Promise.all(
    [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
      .filter((group: string): boolean => group !== GroupName)
      .map(
        (group: string): Promise<unknown> =>
          client
            .send(
              new AdminRemoveUserFromGroupCommand({
                UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
                GroupName: group,
                Username: sub
              })
            )
            .catch(noop)
      )
  );
  await invalidateAllTokens(sub);
}

export async function setEnabledStatus(
  sub: string,
  enabled: boolean
): Promise<void> {
  const client: CognitoIdentityProviderClient = await getCognitoClient();
  if (enabled) {
    await client.send(
      new AdminEnableUserCommand({
        UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
        Username: sub
      })
    );
  } else {
    await client.send(
      new AdminDisableUserCommand({
        UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
        Username: sub
      })
    );
    await invalidateAllTokens(sub, false);
  }
}

export async function invalidateAllTokens(
  sub: string,
  global: boolean = true
): Promise<void> {
  if (sub === (await getCurrentUserSub())) {
    provider = undefined as unknown as CognitoIdentityCredentialProvider;
    await Auth.signOut({
      global
    });
  } else {
    await (
      await getCognitoClient()
    ).send(
      new AdminUserGlobalSignOutCommand({
        UserPoolId: AMPLIFY_CONFIG.Auth.userPoolId,
        Username: sub
      })
    );
  }
}

const stromeePlusLogGroups: Record<StromeePlusLogGroup, string> = {
  [StromeePlusLogGroup.DEVICES]: STROMEE_PLUS_DEFAULT_PREFIX,
  [StromeePlusLogGroup.PROVISIONING]: `/aws/lambda/${STROMEE_PLUS_DEFAULT_PREFIX}-provisioning-lambda`,
  [StromeePlusLogGroup.IOT]: 'AWSIotLogsV2',
  [StromeePlusLogGroup.API]: `/aws/lambda/${STROMEE_PLUS_DEFAULT_PREFIX}-api-lambda`,
  [StromeePlusLogGroup.FLAG]: `/aws/lambda/${STROMEE_PLUS_DEFAULT_PREFIX}-flag-lambda`,
  [StromeePlusLogGroup.HISTORY]: `/aws/lambda/${STROMEE_PLUS_DEFAULT_PREFIX}-history-lambda`,
  [StromeePlusLogGroup.CONNECTION]: `/aws/lambda/${STROMEE_PLUS_DEFAULT_PREFIX}-connection-state-lambda`
};

const stromeeLogGroups: Record<StromeeLogGroup, string> = {
  [StromeeLogGroup.CUSTOMER_API]: `/aws/lambda/${STROMEE_SERVICE_PREFIX}-customer-api-${STROMEE_ENVIRONMENT}-api-lambda`,
  [StromeeLogGroup.SIGNUP]: `/aws/lambda/${STROMEE_DEFAULT_PREFIX}-pre_sign_up-lambda`,
  [StromeeLogGroup.MIGRATION]: `/aws/lambda/${STROMEE_DEFAULT_PREFIX}-user_migration-lambda`,
  [StromeeLogGroup.EMAIL]: `/aws/lambda/${STROMEE_DEFAULT_PREFIX}-custom_email_sender-lambda`
};

function formatLogMessage(message?: string): Array<string | JsonObject> {
  if (!message || !(message.includes('{') && message.includes('}'))) {
    return ([] as string[]).concat(message || []);
  }
  const messageParts: Array<string | JsonObject> = [];
  const firstBracket: number = message.indexOf('{');
  const lastBracket: number = message.lastIndexOf('}') + 1;
  if (firstBracket > 0) {
    messageParts.push(message.substring(0, firstBracket));
  }
  let currentStart: number = firstBracket;
  let currentEnd: number = lastBracket;
  let foundEnd: number = 0;
  do {
    do {
      try {
        messageParts.push(
          JSON.parse(
            message.substring(currentStart, currentEnd).replace(/\n/gm, '\\n')
          )
        );
        foundEnd = currentEnd;
        break;
      } catch (e) {
        currentEnd = message.substring(0, currentEnd - 1).lastIndexOf('}') + 1;
      }
    } while (currentEnd > currentStart);
    const nextStart: number = Math.max(
      message
        .substring(0, lastBracket)
        .indexOf('{', foundEnd || currentStart + 1),
      0
    );
    const betweenPart: string = message.substring(
      foundEnd || currentStart,
      nextStart || message.length
    );
    if (typeof messageParts[messageParts.length - 1] === 'string') {
      messageParts[messageParts.length - 1] += betweenPart;
    } else if (betweenPart) {
      messageParts.push(betweenPart);
    }
    currentStart = nextStart;
    currentEnd = lastBracket;
    foundEnd = 0;
  } while (currentStart > 0 && currentEnd > currentStart);
  return messageParts.map((x: string | JsonObject): string | JsonObject =>
    typeof x === 'string' ? x.trim() : x
  );
}

function encodeLikeAWS(text: string): string {
  return encodeURIComponent(encodeURIComponent(text)).replaceAll('%', '$');
}

export async function getLogs(
  logGroup: StromeePlusLogGroup | StromeeLogGroup,
  startOffset: number
): Promise<LogEntry[]> {
  const client: CloudWatchLogsClient = await getLogsClient();
  const logGroupName: string =
    stromeePlusLogGroups[logGroup as StromeePlusLogGroup] ||
    stromeeLogGroups[logGroup as StromeeLogGroup];
  const rows: FilteredLogEvent[] = [];
  let nextToken: string | undefined;
  do {
    const data: FilterLogEventsCommandOutput = await client.send(
      new FilterLogEventsCommand({
        logGroupName,
        startTime: Date.now() - startOffset * 60 * 1000,
        nextToken
      })
    );
    if (data.events && Array.isArray(data.events)) {
      rows.push(...data.events);
    }
    nextToken = data.nextToken;
  } while (nextToken);
  return rows
    .filter((event: FilteredLogEvent): boolean => (event.timestamp || 0) > 0)
    .map(
      (event: FilteredLogEvent): LogEntry => ({
        timestamp: event.timestamp as number,
        message: formatLogMessage(event.message),
        link: `https://${REGION}.console.aws.amazon.com/cloudwatch/home?region=${REGION}#logsV2:log-groups/log-group/${encodeLikeAWS(
          logGroupName
        )}/log-events/${encodeLikeAWS(
          event.logStreamName as string
        )}$3Fstart$3D${
          (event.timestamp as number) - 10 * 1000
        }$26refEventId$3D${event.eventId}`
      })
    );
}

export async function getOtaJobList(): Promise<OTAUpdateInfo[]> {
  const client: IoTClient = await getIotClient();
  const updates: OTAUpdateInfo[] = [];
  let nextToken: string | undefined;
  do {
    const data: ListOTAUpdatesCommandOutput = await client.send(
      new ListOTAUpdatesCommand({
        nextToken,
        maxResults: 250
      })
    );
    if (data.otaUpdates && Array.isArray(data.otaUpdates)) {
      updates.push(
        ...(
          await Promise.all(
            data.otaUpdates
              .filter(
                ({ otaUpdateId }: OTAUpdateSummary): boolean => !!otaUpdateId
              )
              .map(
                ({ otaUpdateId }: OTAUpdateSummary): Promise<OTAUpdateInfo> =>
                  client
                    .send(
                      new GetOTAUpdateCommand({
                        otaUpdateId
                      })
                    )
                    .then(
                      ({
                        otaUpdateInfo
                      }: GetOTAUpdateCommandOutput): OTAUpdateInfo =>
                        otaUpdateInfo as OTAUpdateInfo
                    )
              )
          )
        ).filter(
          (update: OTAUpdateInfo): boolean =>
            update?.otaUpdateFiles?.[0]?.fileLocation?.s3Location?.bucket ===
            OTA_BUCKET
        )
      );
    }
    nextToken = data.nextToken;
  } while (nextToken);
  return updates;
}

export async function addOtaJob(
  otaUpdateId: string,
  version: string,
  targets: string[],
  s3Location: S3Location,
  targetSelection: TargetSelection
): Promise<OTAUpdateInfo> {
  const client: IoTClient = await getIotClient();
  await client.send(
    new CreateOTAUpdateCommand({
      additionalParameters: {
        version
      },
      otaUpdateId,
      targets,
      protocols: ['MQTT'],
      files: [
        {
          codeSigning: {
            startSigningJobParameter: {
              signingProfileName: SIGNING_PROFILE,
              destination: {
                s3Destination: {
                  bucket: OTA_BUCKET,
                  prefix: 'SignedImages/'
                }
              }
            }
          },
          fileName: '/',
          fileLocation: {
            s3Location
          }
        }
      ],
      targetSelection,
      roleArn: OTA_ROLE
    })
  );
  return client
    .send(
      new GetOTAUpdateCommand({
        otaUpdateId
      })
    )
    .then(
      ({ otaUpdateInfo }: GetOTAUpdateCommandOutput): OTAUpdateInfo =>
        otaUpdateInfo as OTAUpdateInfo
    );
}

export async function removeOtaJob(otaUpdateId: string): Promise<void> {
  await (
    await getIotClient()
  ).send(
    new DeleteOTAUpdateCommand({
      otaUpdateId,
      deleteStream: true,
      forceDeleteAWSJob: true
    })
  );
}

export async function getOtaJobExecutionsList(
  jobId: string
): Promise<CustomJobExecution[]> {
  const client: IoTClient = await getIotClient();
  const updates: JobExecutionSummaryForJob[] = [];
  let nextToken: string | undefined;
  do {
    const data: ListJobExecutionsForJobCommandOutput = await client.send(
      new ListJobExecutionsForJobCommand({
        jobId,
        nextToken
      })
    );
    if (data.executionSummaries && Array.isArray(data.executionSummaries)) {
      updates.push(...data.executionSummaries);
    }
    nextToken = data.nextToken;
  } while (nextToken);
  return updates
    .filter(
      (summary: JobExecutionSummaryForJob): boolean =>
        !!summary.thingArn && !!summary.jobExecutionSummary
    )
    .map(
      (summary: JobExecutionSummaryForJob): CustomJobExecution => ({
        arn: summary.thingArn as string,
        uid: (summary.thingArn as string).replace(THING_ARN_PREFIX, ''),
        ...summary.jobExecutionSummary
      })
    );
}

export async function cancelOtaJobExecution(
  jobId: string,
  thingName: string
): Promise<void> {
  await (
    await getIotClient()
  ).send(
    new CancelJobExecutionCommand({
      jobId,
      thingName,
      force: true
    })
  );
}

export async function getFirmwareList(): Promise<_Object[]> {
  const client: S3Client = await getS3Client();
  const objects: _Object[] = [];
  let ContinuationToken: string | undefined;
  do {
    const data: ListObjectsV2CommandOutput = await client.send(
      new ListObjectsV2Command({
        Bucket: OTA_BUCKET,
        Prefix: '',
        Delimiter: '/',
        ContinuationToken
      })
    );
    if (data.Contents && Array.isArray(data.Contents)) {
      objects.push(...data.Contents);
    }
    ContinuationToken = data.NextContinuationToken;
  } while (ContinuationToken);
  return objects;
}

export async function getFirmwareLocationList(): Promise<S3Location[]> {
  const client: S3Client = await getS3Client();
  const objects: S3Location[] = [];
  let KeyMarker: string | undefined;
  let VersionIdMarker: string | undefined;
  do {
    const data: ListObjectVersionsCommandOutput = await client.send(
      new ListObjectVersionsCommand({
        Bucket: OTA_BUCKET,
        Prefix: '',
        Delimiter: '/',
        KeyMarker,
        VersionIdMarker
      })
    );
    if (data.Versions && Array.isArray(data.Versions)) {
      objects.push(
        ...data.Versions.filter(
          (object: ObjectVersion): boolean =>
            !!object.IsLatest && !!object.Key && !!object.VersionId
        ).map(
          (object: ObjectVersion): S3Location => ({
            bucket: OTA_BUCKET,
            key: object.Key as string,
            version: object.VersionId as string
          })
        )
      );
    }
    KeyMarker = data.NextKeyMarker;
    VersionIdMarker = data.NextVersionIdMarker;
  } while (KeyMarker || VersionIdMarker);
  return objects;
}

export async function addFirmware(Key: string, Body: File): Promise<_Object> {
  await (
    await getS3Client()
  ).send(
    new PutObjectCommand({
      Bucket: OTA_BUCKET,
      Key,
      Body
    })
  );
  return {
    Key,
    Size: Body.size,
    LastModified: new Date()
  };
}

export async function removeFirmware(Key: string): Promise<void> {
  await (
    await getS3Client()
  ).send(
    new DeleteObjectCommand({
      Bucket: OTA_BUCKET,
      Key
    })
  );
}

export async function generatePresignedLink(Key: string): Promise<string> {
  return getSignedUrl(
    await getS3Client(),
    new GetObjectCommand({
      Bucket: OTA_BUCKET,
      Key
    }),
    { expiresIn: 600 }
  );
}
